import { useQuery } from "@tanstack/react-query";
import { getInvoicesCount } from "Services/invoices";
import { trackError } from "Utils/errorMonitoring";

export default function useInvoicesCount() {
    const { data: invoicesCount = 0, isLoading } = useQuery({
        queryKey: ["invoices", "count"],
        queryFn: getInvoicesCount,
        onError: error => trackError(error),
    });

    return {
        invoicesCount,
        isLoading
    }
}