import { getProjectSelected, getProjectSelectedBilling, updateProjectSelected, updateProjectSelectedBilling } from "Services/user";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";

export default function useProjectSelected() {
    const { data: project = {}, isLoading } = useQuery({
        queryKey: ["projects", "selected"],
        queryFn: getProjectSelected,
        onError: error => trackError(error),
    });

    const { data: projectBilling = {}, isLoading: isLoadingBilling, refetch: refetchBilling } = useQuery({
        queryKey: ["project-selected-billing"],
        queryFn: getProjectSelectedBilling,
        onError: error => trackError(error),
    });

    const queryClient = useQueryClient();

    const { mutate: updateProject } = useMutation({
        mutationFn: updateProjectSelected,
        onMutate: (payload) => {
            const previousProject = queryClient.getQueryData(["projects", "selected"]);

            queryClient.setQueryData(["projects", "selected"], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    ...payload,
                }
            });

            return { previousProject };
        },
        onError: (err, _module, context) => {
            trackError(err);
            queryClient.setQueryData(["projects", "selected"], context.previousProject);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["projects", "selected"] });
        }
    });

    const { mutate: updateProjectBilling } = useMutation({
        mutationFn: updateProjectSelectedBilling,
        onMutate: (payload) => {
            const previousProjectBilling = queryClient.getQueryData(["project-selected-billing"]);

            queryClient.setQueryData(["project-selected-billing"], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    ...payload,
                }
            });

            return { previousProjectBilling };
        },
        onError: (err, _module, context) => {
            trackError(err);
            queryClient.setQueryData(["project-selected-billing"], context.previousProject);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["project-selected-billing"] });
        }
    });

    return {
        project,
        projectBilling,
        isLoading,
        isLoadingBilling,
        updateProject,
        updateProjectBilling,
        refetchBilling
    }
}