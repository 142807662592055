import React, { useState } from "react";
import classes from "./AppLayout.module.css";
import useProvidersConnections from "Hooks/useProvidersConnections";
import { PROVIDER_CONNECTION_STATUSES } from "Utils/constants";
import ProviderConnectionConnectDialog from "Sections/ProviderConnectionConnectDialog";
import { useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import Alert from "Components/Alert";
import { formatDuration } from "date-fns";
import { es } from 'date-fns/locale';
import useLocale from "Hooks/useLocale";
import { htmlDecode } from "Utils/formatters";
import useSettingsStorage from "Hooks/useSettingsStorage";
import Dialog from "Components/Dialog";
import ProviderHistoricalSync from "Sections/ProviderHistoricalSync";

const MODAL_TYPES = {
    SUCCESS: "success",
    CONNECT: "connect",
    HISTORICAL_SYNC: "historical-sync",
};

const getExpiredProvider = (providersConnections) => {
    const expiredProviders = providersConnections
        .filter(item => [PROVIDER_CONNECTION_STATUSES.EXPIRED].includes(item.status))
        .filter(item => !item.is_manual_sync)
        .filter(item => item.products.some(product => !product.is_hidden && !product.is_deleted))
        .filter(item => !item.is_premium_blocked);

    if (expiredProviders.length === 0) {
        return null;
    }

    return expiredProviders[0];
}

const getLongSyncProvider = (providersConnections, excludeIds) => {
    return providersConnections
        .filter(item => !excludeIds.includes(item.id))
        .filter(item => item.status !== PROVIDER_CONNECTION_STATUSES.SYNCHRONIZING)
        .filter(item => item.connector.service !== "manual")
        .filter(item => !!item.last_dump) // first connection is null
        .find(item => !item.is_long_synched);
}

const getDuration = (days = 0, { locale } = {}) => {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const daysLeft = days - years * 365 - months * 30;

    return formatDuration({
        years,
        months,
        days: daysLeft,
    }, {
        format: ["years", "months", "days"],
        locale: locale === "es-ES" ? es : undefined,
    })
}

const formatExcludedHistoricalSyncProviders = (excludedHistoricalSyncProviders) => {
    if (!excludedHistoricalSyncProviders) return [];

    return excludedHistoricalSyncProviders.split(",");
}

export default function AppLayoutProvidersCTA() {
    const [showModal, setShowModal] = useState(null);

    const { validProvidersConnections: providersConnections } = useProvidersConnections();
    const { isLoading, settings = {}, setSetting } = useSettingsStorage();
    const excludedHistoricalSyncProviders = formatExcludedHistoricalSyncProviders(settings.excluded_historical_sync_providers?.value);
    const expiredProvider = getExpiredProvider(providersConnections);
    const longSyncProvider = getLongSyncProvider(providersConnections, excludedHistoricalSyncProviders);

    const { t } = useTranslation("common");
    const locale = useLocale();

    const queryClient = useQueryClient();

    const closeDialog = () => setShowModal(null);

    const handleConnect = () => {
        setShowModal(null)
        queryClient.invalidateQueries({
            queryKey: ["providers-connections"]
        });
    }

    const hasExpiredProvider = !!expiredProvider && !!expiredProvider.connector;
    const showHistoricalSync = !hasExpiredProvider && !!longSyncProvider && !isLoading;

    const duration = getDuration(longSyncProvider?.connector.update_delta_initial, { locale });
    const historicalDuration = getDuration(longSyncProvider?.connector.update_delta_historical, { locale });

    const handleDismissSyncAlert = (providerId) => {
        setSetting({
            field: "excluded_historical_sync_providers",
            value: [...excludedHistoricalSyncProviders, providerId].toString(),
            is_global: true,
        });
    }

    return (
        <>
            <ProviderConnectionConnectDialog
                open={showModal === MODAL_TYPES.CONNECT}
                onClose={closeDialog}
                onConnect={handleConnect}
                providerConnection={expiredProvider}
            />

            <Dialog
                size="sm"
                open={showModal === MODAL_TYPES.HISTORICAL_SYNC}
                onClose={closeDialog}
            >
                {showModal === MODAL_TYPES.HISTORICAL_SYNC && (
                    <ProviderHistoricalSync
                        providerConnection={longSyncProvider}
                        onSync={handleConnect}
                    />
                )}
            </Dialog>

            {hasExpiredProvider && (
                <Alert
                    className={classes.spaceBottom}
                    title={t("provider-connections.connection-error")}
                    description={
                        <Trans t={t} i18nKey="provider-connections.connection-error-description">
                            {{ provider: expiredProvider.name }}
                        </Trans>
                    }
                    variant="provider-connection"
                    variantProps={{
                        image: expiredProvider.logo_url,
                        status: "error"
                    }}
                    cta={{
                        label: t("provider-connections.fix-connection"),
                        onClick: () => setShowModal(MODAL_TYPES.CONNECT)
                    }}
                />
            )}

            {showHistoricalSync && (
                <Alert
                    className={classes.spaceBottom}
                    variant="provider-connection"
                    title={htmlDecode(t("alert-historical-sync-title", { duration, name: longSyncProvider.name }))}
                    description={htmlDecode(t("alert-historical-sync-description", { duration: historicalDuration }))}
                    variantProps={{
                        image: longSyncProvider.logo_url,
                        status: "info"
                    }}
                    onClose={() => handleDismissSyncAlert(longSyncProvider.id)}
                    cta={{
                        label: t("alert-historical-sync-cta"),
                        onClick: () => setShowModal(MODAL_TYPES.HISTORICAL_SYNC)
                    }}
                    secondaryCta={{
                        label: t("alert-historical-sync-cta-later"),
                        onClick: () => handleDismissSyncAlert(longSyncProvider.id)
                    }}
                />
            )}
        </>
    );
}
