import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./ProviderSelector.module.css";
import { useTranslation } from "react-i18next";
import Switch from "Components/Switch";
import { updateProvider } from "Services/providers";
import { useQueryClient } from "@tanstack/react-query";
import { useErrors } from "Components/ErrorsProvider";
import Button from "Components/Button";
import InputField from "Components/InputField";

export default function ProviderConnectorsSupportForm(props) {
    const { provider, onCancel } = props;

    const { t } = useTranslation("providers");
    const queryClient = useQueryClient();
    const { showUnknownError } = useErrors();
    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState(provider.name);
    const [enabled, setEnabled] = useState(provider.is_enabled);

    const handleSubmit = async () => {
        setSubmitting(true);
        const data = {
            name: name.trim(),
            is_enabled: enabled,
        }

        try {
            await updateProvider(provider.id, data);
            await queryClient.invalidateQueries({ queryKey: ["providers"] });
            onCancel();
        } catch (error) {
            showUnknownError(error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className="d-flex gap-2">
            <div>
                <img
                    src={provider.logo_url}
                    className={classes.providerLogo}
                    alt={provider.name}
                />
            </div>

            <div className="flex-grow-1 d-grid gap-2">
                <div>
                    <InputField
                        label={t("name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div>
                    <label className="d-inline-flex gap-2 align-items-center">
                        <Switch
                            size="small"
                            checked={enabled}
                            onChange={(e) => setEnabled(e.target.checked)}
                        />
                        {t("enabled-provider")}
                    </label>
                </div>

                <div className="pt-3">
                    <Button primary isBusy={submitting} onClick={handleSubmit}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

ProviderConnectorsSupportForm.propTypes = {
    provider: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
} 