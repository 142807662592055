import { useQuery } from "@tanstack/react-query";
import { getTransactionsCount } from "Services/transactions";
import { trackError } from "Utils/errorMonitoring";

export default function useTransactionsCount() {
    const { data: transactionsCount = 0, isLoading } = useQuery({
        queryKey: ["transactions-count"],
        queryFn: getTransactionsCount,
        onError: error => trackError(error),
    })

    return {
        transactionsCount,
        isLoading
    }
}