import { useQuery } from "@tanstack/react-query";
import { getDashboards } from "Services/dashboards";
import { trackError } from "Utils/errorMonitoring";

export default function useDashboards() {
    const { data: dashboards = [], isLoading } = useQuery({
        queryKey: ["dashboards"],
        queryFn: getDashboards,
        onError: error => trackError(error)
    });

    return {
        dashboards, isLoading
    }
}