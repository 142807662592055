
import PropTypes from "prop-types";
import React from "react";

const Restore = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<path d="M14.555 9.25a.75.75 0 0 1 .75.75v7.5c0 1.216-.956 2.25-2.194 2.25H5.889c-1.239 0-2.195-1.034-2.195-2.25v-6a.75.75 0 0 1 1.5 0v6c0 .441.338.75.695.75h7.222c.357 0 .694-.309.694-.75V10a.75.75 0 0 1 .75-.75Z" fill={color} fillRule="evenodd"/>
	<path d="M8.056 12.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V13a.75.75 0 0 1 .75-.75ZM10.944 12.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V13a.75.75 0 0 1 .75-.75ZM2.338 8.856a.779.779 0 0 1 .315-1.03L14.13 1.487c.363-.2.81-.064.998.305a.779.779 0 0 1-.314 1.03L3.336 9.16a.722.722 0 0 1-.998-.305Z" fill={color} fillRule="evenodd"/>
	<path d="M6.444 4.047a.75.75 0 0 0-.283.998L6.84 6.37a.779.779 0 0 1-.314 1.03.722.722 0 0 1-.999-.305L4.848 5.77c-.556-1.086-.168-2.462.912-3.058l2.55-1.409c1.08-.596 2.396-.161 2.953.925l.678 1.325a.779.779 0 0 1-.315 1.03.722.722 0 0 1-.998-.305L9.95 2.953a.696.696 0 0 0-.956-.315l-2.55 1.409ZM15.76 3.042a.75.75 0 0 1 1.046-.173l2.51 1.825a.75.75 0 0 1 .174 1.027l-1.757 2.607a.75.75 0 0 1-1.04.21.75.75 0 0 1-.196-1.043l1.35-2.002-1.928-1.402a.75.75 0 0 1-.16-1.05Z" fill={color} fillRule="evenodd"/>
	<path d="M14.434 6.927c-.997.477-1.737 1.244-2.039 2.499a.75.75 0 0 1-1.458-.352c.42-1.745 1.496-2.853 2.85-3.5 1.321-.633 2.894-.824 4.38-.824a.75.75 0 0 1 0 1.5c-1.386 0-2.702.184-3.733.677Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default Restore;

Restore.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
