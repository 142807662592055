import React, { useEffect, useRef, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import useProvidersConnections from "Hooks/useProvidersConnections";
import ProviderConnectionsContext from "./ProviderConnectionsContext";
import ProviderConnectionsErrorModal from "./ProviderConnectionsErrorModal";
import { PROVIDER_CONNECTION_STATUSES } from "Utils/constants";
import { useSnackbar } from "notistack";
import { getProviderConnection } from "Services/providersConnections";
import { trackError } from "Utils/errorMonitoring";
import { useQueryClient } from "@tanstack/react-query";
import useProviderConnectionsCredentials from "Hooks/useProviderConnectionsCredentials";
import { useUser } from "Components/UserProvider/useUser";

const DELAY_BETWEEN_REQUESTS = 5000;
const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const processingStatus = [
    PROVIDER_CONNECTION_STATUSES.CREATING_PROCESSING,
    PROVIDER_CONNECTION_STATUSES.RECONNECTING_PROCESSING,
]

const errorStatus = [
    PROVIDER_CONNECTION_STATUSES.CREATING_ERROR,
    PROVIDER_CONNECTION_STATUSES.RECONNECTING_ERROR,
];

export default function ProviderConnectionsProvider({ children }) {
    const { providersConnections } = useProvidersConnections();
    const { refreshUser } = useUser();
    const hasBeenChecked = useRef(false);
    const [queueConnectionFeedbacks, setQueueConnectionFeedbacks] = useState([]);
    const intervalRef = useRef({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { set } = useProviderConnectionsCredentials();

    const checkCreation = useCallback((providerConnection) => {
        getProviderConnection(providerConnection.id).then(async providerConnectionUpdated => {
            if (!providerConnectionUpdated) return;

            if (processingStatus.includes(providerConnectionUpdated.status)) {
                return;
            }

            closeSnackbar(`${providerConnection.id}-creating`)
            clearInterval(intervalRef.current[providerConnection.id] ?? null);
            await wait(500);

            if (errorStatus.includes(providerConnectionUpdated.status)) {
                setQueueConnectionFeedbacks(prev => [...prev, providerConnectionUpdated]);
            } else {
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionCreationSuccess", key: `${providerConnection.id}-creation-success` })
                await queryClient.invalidateQueries({ queryKey: ["providers-connections"] });
                refreshUser(); // remove onboarding add provider banner
            }
        }).catch((errorResponse) => {
            clearInterval(intervalRef.current[providerConnection.id] ?? null);
            closeSnackbar(`${providerConnection.id}-creating`)
            trackError(errorResponse);
        })
    }, []);

    useEffect(() => {
        if (!providersConnections.length || hasBeenChecked.current) return;

        hasBeenChecked.current = true;
        providersConnections.forEach(providerConnection => {
            if (processingStatus.includes(providerConnection.status)) {
                enqueueSnackbar(providerConnection.name, { variant: "providerConnectionCreating", key: `${providerConnection.id}-creating`, persist: true, icon: providerConnection.logo_url })
                intervalRef.current[providerConnection.id] = setInterval(() => checkCreation(providerConnection), DELAY_BETWEEN_REQUESTS);
            }

            if (errorStatus.includes(providerConnection.status)) {
                setQueueConnectionFeedbacks(prev => [...prev, providerConnection]);
            }
        });
    }, [providersConnections]);

    const contextValue = useMemo(() => ({
        checkConnection: (connection) => {
            if (connection.credentials) {
                set(connection.id, connection.credentials);
            }
            enqueueSnackbar(connection.name, { variant: "providerConnectionCreating", key: `${connection.id}-creating`, persist: true, icon: connection.logo_url })
            intervalRef.current[connection.id] = setInterval(() => checkCreation(connection), DELAY_BETWEEN_REQUESTS);
        }
    }), []);

    const connectionFeedback = queueConnectionFeedbacks[0] ?? null;

    const handleCloseErrorModal = () => {
        setQueueConnectionFeedbacks([])

        const queueUpdated = queueConnectionFeedbacks.slice(1);
        setTimeout(() => {
            setQueueConnectionFeedbacks(queueUpdated);
        }, 1000);
    }

    return (
        <ProviderConnectionsContext.Provider value={contextValue}>
            {connectionFeedback && (
                <ProviderConnectionsErrorModal
                    open={!!connectionFeedback}
                    onClose={handleCloseErrorModal}
                    providerConnection={connectionFeedback}
                />
            )}

            {children}
        </ProviderConnectionsContext.Provider>
    )
}


ProviderConnectionsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};