import { useQuery } from "@tanstack/react-query";
import { getProjects } from "Services/user";
import { trackError } from "Utils/errorMonitoring";

export default function useProjects() {
    const { data: projects = [], isLoading } = useQuery({
        queryKey: ["projects"],
        queryFn: getProjects,
        onError: error => trackError(error),
    });

    return {
        projects,
        isLoading
    }
}
