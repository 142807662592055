import { useQuery } from "@tanstack/react-query";
import { getProviders } from "Services/providers";
import { trackError } from "Utils/errorMonitoring";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useProviders() {
    const { data = [], isLoading } = useQuery({
        queryKey: ["providers"],
        queryFn: getProviders,
        onError: error => trackError(error),
    });

    const { t } = useTranslation("providers");

    const providers = useMemo(() => {
        return data.map(item => ({
            ...item,
            name: item.name === "Banco Manual" ? t("manual-bank-provider") : item.name,
        }))
    }, [data, t]);

    const availableProviders = useMemo(() => {
        return providers.filter(item => item.is_enabled)
    }, [providers]);

    return {
        isLoading,
        availableProviders,
        providers
    }
}