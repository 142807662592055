import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import { changeProjectWorkspaceSelected } from "Services/user";
import ItemSelector from "Components/ItemSelector";
import Loading from "Components/Loading";
import Icon from "Components/Icon";
import { useErrors } from "Components/ErrorsProvider";
import { useQueryClient } from "@tanstack/react-query";
import AvatarUser from "Components/AvatarUser";
import { useUser } from "Components/UserProvider/useUser";
import { useTranslation } from "react-i18next";
import useProjectWorkspaces from "Hooks/useProjectWorkspaces";
import IconButton from "Components/IconButton";
import classes from "./ChangeWorkspaceButton.module.css";
import useWorkspaceSelected from "Hooks/useWorkspaceSelected";
import ChangeWorkspaceCreateForm from "./ChangeWorkspaceCreateForm";
import upgradeIconImage from "assets/upgrade-icon@2x.png";
import usePlansAndPricingDialog from "Hooks/usePlansAndPricingDialog";
import Button from "Components/Button";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "Utils/constants";
import { useSearch } from "Components/SearchProvider";

const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));

export default function ChangeWorkspaceSelectedModal(props) {
    const { open, onClose, projectId, name, onBack, isOwner } = props;
    const { showUnknownError } = useErrors();
    const queryClient = useQueryClient();
    const [workspaceSelectedId, setWorkspaceSelectedId] = useState(null);
    const { refreshUser } = useUser();
    const { workspaces, isLoading } = useProjectWorkspaces(projectId, {
        enabled: !!projectId
    });

    const params = useParams();
    const navigate = useNavigate();
    const { clearFilters } = useSearch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showRequestPayment, setShowRequestPayment] = useState(false);
    const { workspace: workspaceSelected, isLoading: isLoadingWorspaceSelected } = useWorkspaceSelected();
    const { openPlansAndPricing } = usePlansAndPricingDialog();

    const { t } = useTranslation("common");

    useEffect(() => {
        if (open) {
            setWorkspaceSelectedId(null);
            setShowRequestPayment(false);
        }
    }, [open]);

    const handleSelectWorkspace = async (workspaceId) => {
        setWorkspaceSelectedId(workspaceId);


        try {
            await changeProjectWorkspaceSelected(workspaceId);
            refreshUser()

            const hasParams = Object.keys(params).length > 0;
            const hasSearchParams = searchParams.size > 0;

            if (hasParams) {
                navigate(ROUTES.settings);
                await wait(300);
            } else if (hasSearchParams) {
                setSearchParams({});
                clearFilters();
            }

            await queryClient.invalidateQueries()
            onClose();
        } catch (error) {
            const hasRequiresPaidError = error?.response?.status === 400 && error?.response?.data?.description === "requires_paid_subscription";
            if (hasRequiresPaidError) {
                setShowRequestPayment(true);
            } else {
                showUnknownError(error)
                onClose();
            }
        } finally {
            setWorkspaceSelectedId(null);
        }
    }

    const handleCreateWorkspace = (workspace) => {
        handleSelectWorkspace(workspace.id);
    }

    const showLoading = isLoading || isLoadingWorspaceSelected;

    const handleShowPlans = () => {
        openPlansAndPricing();
        onClose();
    }

    if (showRequestPayment) {
        return (
            <Dialog size="sm" open={open} onClose={onClose}>
                <div className="text-center">
                    <div className="mb-4">
                        <img src={upgradeIconImage} width={84} height={84} alt="" className="mb-3" />
                        <h2 className="mb-2">
                            {t("modal-limit-change-workspace-title")}
                        </h2>
                        <p className="text-muted">
                            {t("modal-limit-change-workspace-description")}
                        </p>
                    </div>
                    <Button primary size="large" onClick={handleShowPlans}>
                        {t("see-plans-and-prices")}
                    </Button>
                </div>
            </Dialog>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="sm"
        >
            {onBack && (
                <IconButton onClick={onBack} className={classes.goBack}>
                    <Icon size={24} name="back-2" color="#C0BFBE" />
                </IconButton>
            )}

            <div className="text-center mb-4">
                <h4>{name}</h4>
                <div className="text-muted pb-2">
                    {workspaces?.length === 0 && isOwner
                        ? t("select-workspace.create-workspace")
                        : t("select-workspace.select-workspace")
                    }
                </div>
            </div>

            {showLoading && (
                <div className="py-4">
                    <Loading message={t("select-workspace.workspaces-loading")} />
                </div>
            )}

            {!showLoading && (
                <>
                    <div className="d-grid gap-3" style={{ pointerEvents: workspaceSelectedId ? "none" : "all" }}>
                        {workspaces.map((workspace) => (
                            <ItemSelector
                                customImage={<AvatarUser name={workspace.name} size={50} />}
                                isBusy={workspaceSelectedId === workspace.id}
                                key={workspace.id}
                                title={workspace.name}
                                onClick={() => handleSelectWorkspace(workspace.id)}
                                icon={workspaceSelected.id === workspace.id ? <Icon name="circle-check" size={32} color="#74B62C" /> : <div />}
                            />
                        ))}
                    </div>

                    {workspaces.length === 0 && isOwner && (
                        <ChangeWorkspaceCreateForm
                            onError={onClose}
                            onCreate={handleCreateWorkspace}
                            projectId={projectId}
                        />
                    )}

                    {workspaces.length === 0 && !isOwner && (
                        <div className="text-center">
                            <div className={classes.message}>
                                {t("select-workspace.owner-not-assigned")}

                            </div>

                            <Button primary onClick={onClose}>
                                {t("select-workspace.accept")}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </Dialog>
    )
}

ChangeWorkspaceSelectedModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    name: PropTypes.string,
    isOwner: PropTypes.bool,
}