import React, { useEffect } from "react";
import propTypes from "prop-types";
import useSettingsStorage from "Hooks/useSettingsStorage";
import { Fill } from "nano-slots";
import Alert from "Components/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "Utils/constants";
import { useTranslation } from "react-i18next";
import useProvidersConnections from "Hooks/useProvidersConnections";
import useIsOffline from "Hooks/useIsOffline";
import { useSnackbar } from "notistack";

export default function UserNoticesProvider({ children }) {
    const { settings, isLoading, setSetting } = useSettingsStorage();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation("common");
    const { fakeProviders, isLoading: isProvidersLoading } = useProvidersConnections();

    const hasConnectedBank = !isProvidersLoading && fakeProviders.length === 0;
    const isAffiliatesPage = pathname === ROUTES.affiliates;
    const hasClosedAffiliatesAlert = !isLoading && settings?.alert_affiliates_closed?.value;

    const showAlertAffiliatesClosed = hasConnectedBank && !isAffiliatesPage && !hasClosedAffiliatesAlert;

    const isOffline = useIsOffline();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isOffline) {
            enqueueSnackbar(t("currently-offline"));
        }
    }, [isOffline]);

    const setAffiliatesClosed = () => {
        setSetting({
            field: "alert_affiliates_closed",
            value: true,
            is_global: true,
        });
    };

    return (
        <>
            {showAlertAffiliatesClosed && (
                <Fill name="app-layout-notices">
                    <Alert
                        className="mb-4"
                        variant="affiliate"
                        title={t("notices.affiliates-title")}
                        description={t("notices.affiliates-description")}
                        onClose={setAffiliatesClosed}
                        cta={{
                            label: t("notices.affiliates-cta"),
                            onClick: () => {
                                navigate(ROUTES.affiliates);
                                setAffiliatesClosed();
                            },
                        }}
                    />
                </Fill>
            )}

            {children}
        </>
    )
}

UserNoticesProvider.propTypes = {
    children: propTypes.node,
};