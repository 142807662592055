import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import { rejectProviderConnection } from "Services/providersConnections";
import { trackError } from "Utils/errorMonitoring";
import ProviderConnectionsErrorManager from "./ProviderConnectionsErrorManager";
import { useQueryClient } from "@tanstack/react-query";

export default function ProviderConnectionsErrorModal(props) {
    const { open, onClose, providerConnection } = props;
    const [enableClose, setEnableClose] = useState(false)
    const queryClient = useQueryClient();

    const handleClearConnection = async () => {
        try {
            await rejectProviderConnection(providerConnection.id)
            await queryClient.invalidateQueries({ queryKey: ["providers-connections"] });
        } catch (error) {
            trackError(error)
        }
    }

    return (
        <Dialog
            open={open}
            size="sm"
            onClose={onClose}
            disableClose={!enableClose}
            blockClose={!enableClose}
        >
            <ProviderConnectionsErrorManager
                providerConnection={providerConnection}
                {...providerConnection.status_message}
                onClose={onClose}
                onClearConnection={handleClearConnection}
                onEnableClose={() => setEnableClose(true)}
            />
        </Dialog>
    )
}

ProviderConnectionsErrorModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    providerConnection: PropTypes.object.isRequired,
};
