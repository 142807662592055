import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormProvider, { RHFTextField } from 'Components/HookForm';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Button from "Components/Button";
import classes from "./CreateManualProviderConnection.module.css";
import Icon from "Components/Icon";
import { createProviderConnectionManual } from "Services/providersConnections";
import { useErrors } from "Components/ErrorsProvider";
import CreateManualProviderConnectionRow from "./CreateManualProviderConnectionRow";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const INITIAL_PRODUCT = {
    name: "",
    type: "cash",
    currency: "EUR",
}

export default function CreateManualProviderConnection(props) {
    const { connectorId, onConnect, onCancel } = props;
    const [submitting, setSubmitting] = useState(false);
    const { showUnknownError } = useErrors();
    const queryClient = useQueryClient();

    const { t } = useTranslation("common");

    const schema = yup.object().shape({
        name: yup.string().required(t("manual-bank.name-is-required")),
        products: yup.array().min(1, t("manual-bank.product-is-required")).of(yup.object().shape({
            name: yup.string().required(t("manual-bank.name-is-required")),
            type: yup.string(),
            currency: yup.string(),
        }))
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            products: [INITIAL_PRODUCT],
        },
    });

    const { handleSubmit, control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "products"
    });

    const submit = async (data) => {
        setSubmitting(true);
        try {
            const response = await createProviderConnectionManual({
                connectorId,
                name: data.name,
                products: data.products
            })
            await queryClient.invalidateQueries({ queryKey: ["providers-connections"] });

            onConnect(response);
        } catch (error) {
            onCancel();
            showUnknownError(error)
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        const modal = document.querySelector(".modal-dialog");

        if (modal) {
            modal.classList.remove("modal-md");
            modal.classList.add("modal-xl");
        }

        return () => {
            if (modal) {
                modal.classList.remove("modal-xl");
                modal.classList.add("modal-md");
            }
        }
    }, []);

    return (
        <FormProvider onSubmit={handleSubmit(submit)} methods={methods}>
            <div className="mb-4">
                <h4>{t("manual-bank.new-manual-bank")}</h4>
                <div className="text-muted">
                    {t("manual-bank.new-manual-bank-description")}
                </div>
            </div>

            <div className="py-3">
                <RHFTextField
                    name="name"
                    label={t("manual-bank.bank-name")}
                    autoFocus
                />
            </div>

            <div className="mb-5">
                <div className={classes.uppercaseTitle}>
                    {t("manual-bank.products")}
                </div>
                {fields.length === 0 && (
                    <div className={classes.emptyState}>
                        {t("manual-bank.add-at-least-one-product")}
                    </div>
                )}
                {fields.map((field, index) => (
                    <CreateManualProviderConnectionRow key={field.id} index={index} onRemove={() => remove(index)} />
                ))}
                <div>
                    <button type="button" className={classes.textButton} onClick={() => append(INITIAL_PRODUCT)}>
                        <Icon name="circle-plus" color="currentColor" size={22} />
                        {t("manual-bank.add-product")}
                    </button>
                </div>
            </div>
            <div className="text-center">
                <Button isBusy={submitting} primary size="large" type="submit">
                    {t("manual-bank.connect")}
                </Button>
            </div>
        </FormProvider>
    )
}

CreateManualProviderConnection.propTypes = {
    connectorId: PropTypes.string.isRequired,
    onConnect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}