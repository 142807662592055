export const ROUTES = {
    // auth
    signIn: "/iniciar-sesion",
    magicCode: "/iniciar-sesion/codigo",
    signUp: "/registrarse",
    signUpEmailConfirm: "/registrarse/confirmar-email",
    reactivate: "/reactivar",
    forgotPassword: "/recuperar-contrasena",
    emailVerify: "/verificar_email",
    guestSignUp: "/aceptar_invitacion",
    // onboarding
    onboarding: "/bienvenido",
    // select workspace
    selectWorkspace: "/seleccionar-espacio-de-trabajo",
    // app
    providers: "/bancos",
    history: "/historial",
    alerts: "/alertas",
    automations: "/reglas-automaticas",
    historical: "/historial",
    search: "/buscar",
    callbackNordigen: "/callbacks/nordigen",
    callbackIndexaCapital: "/callbacks/indexa_capital",
    dashboards: "/dashboards",
    reports: "/informes",
    callbackGoogle: "/callbacks/google",
    categories: "/etiquetas",
    invoices: "/cobros-y-pagos",
    invoicesCalendar: "/cobros-y-pagos/calendario",
    contacts: "/contactos",
    settings: "/ajustes",
    profile: "/perfil",
    forecasts: "/previsiones",
    integrations: "/integraciones",
    affiliates: "/afiliados",
    archive: "/papelera",
    // others
    landing: "https://banktrack.com/",
}

export const HELP_ROUTES = {
    ES: "https://docs.banktrack.com/",
    EN: "https://docs.banktrack.com/en",
}

export const LEGAL_ROUTES = {
    privacy: {
        ES: "https://banktrack.com/privacidad",
        EN: "https://banktrack.com/en/privacy-policy",
    },
    terms: {
        ES: "https://banktrack.com/condiciones-de-uso",
        EN: "https://banktrack.com/en/terms-and-conditions",
    },
    affiliateTerms: {
        ES: "https://banktrack.com/condiciones-de-afiliacion",
        EN: "https://banktrack.com/en/affiliate-terms",
    }
}

export const ROUTES_WITH_SUBROUTES = [
    ROUTES.dashboards,
    ROUTES.forecasts,
]

export const SEARCH_FILTERS = {
    INVOICES_MODE: {
        ALL: "all",
        ONLY_WITH_INVOICES: "only_with_invoices",
        ONLY_WITHOUT_INVOICES: "only_without_invoices",
    },
    FILES_MODE: {
        ALL: "all",
        ONLY_WITH_FILES: "only_with_files",
        ONLY_WITHOUT_FILES: "only_without_files",
    },
    IGNORED_MODE: {
        ALL: "all",
        ONLY_IGNORED: "only_ignored",
        ONLY_NOT_IGNORED: "only_not_ignored",
    },
    REVERSED_SIGN_MODE: {
        ALL: "all",
        ONLY_REVERSED_SIGN: "only_reversed_sign",
        ONLY_NOT_REVERSED_SIGN: "only_not_reversed_sign",
    },
    SPLIT_MODE: {
        ALL: "all",
        ONLY_SPLIT: "only_split",
        ONLY_NOT_SPLIT: "only_not_split",
    }
}

export const VIDEOS_TOOLS = {
    dashboards: "https://www.youtube.com/embed/oZJCmJp4YDA",
    alerts: "https://www.youtube.com/embed/VpkUt9niWms",
    automations: "https://www.youtube.com/embed/t_RbNALwb-c",
    reports: "https://www.youtube.com/embed/e_nKxRGdG00",
}

export const UNCATEGORIZED_CATEGORY = { id: 'untagged', label: 'Sin categoría', color: '#787878' };
export const CUSTOM_PERIOD = "custom";
export const All_TIME_PERIOD = "all_time";

export const PERIODS = [{
    label: "Hoy",
    i18n: "periods.today",
    value: "this_day"
}, {
    label: 'Ayer',
    i18n: "periods.yesterday",
    value: 'past_day'
}, {
    label: 'Esta semana',
    i18n: "periods.this-week",
    value: 'this_week'
}, {
    label: 'Semana pasada',
    i18n: "periods.last-week",
    value: 'past_week'
}, {
    label: 'Este mes',
    i18n: "periods.this-month",
    value: 'this_month'
}, {
    label: 'Mes pasado',
    i18n: "periods.last-month",
    value: 'past_month'
}, {
    label: 'Este trimestre',
    i18n: "periods.this-quarter",
    value: 'this_quarter'
}, {
    label: 'Trimestre pasado',
    i18n: "periods.last-quarter",
    value: 'past_quarter'
}, {
    label: 'Este año',
    i18n: "periods.this-year",
    value: 'this_year'
}, {
    label: 'Año pasado',
    i18n: "periods.last-year",
    value: 'past_year'
}, {
    label: 'Desde el inicio',
    i18n: "periods.all-time",
    value: 'all_time'
}, {
    label: "Rango personalizado",
    i18n: "periods.custom",
    value: CUSTOM_PERIOD
}]

export const ONBOARDING_TASKS = [{
    id: "create_provider_connection",
    i18n: "onboarding.add-provider",
    link: ROUTES.providers
}, {
    id: "create_dashboard",
    i18n: "onboarding.create-dashboard",
    link: ROUTES.dashboards
}, {
    id: "create_forecast",
    i18n: "onboarding.create-forecast",
    link: ROUTES.forecasts
}, {
    id: "create_report",
    i18n: "onboarding.create-report",
    link: ROUTES.reports
}, {
    id: "create_alert",
    i18n: "onboarding.create-alert",
    link: ROUTES.alerts
}, {
    id: "create_automation",
    i18n: "onboarding.create-automation",
    link: ROUTES.automations
}, {
    id: "create_category",
    i18n: "onboarding.create-category",
    link: ROUTES.categories
}]

export const INVOICES_STATUSES = {
    NOT_RECONCILED_LATE: "NOT_RECONCILED_LATE",
    NOT_RECONCILED: "NOT_RECONCILED",
    RECONCILED: "RECONCILED",
}

export const INVOICE_TYPES = {
    CASH_IN: 'cash_in',
    CASH_OUT: 'cash_out',
}

export const INVOICES_CREATION_STATUSES = {
    PENDING_UPLOAD: "PENDING_UPLOAD",
    PENDING_SCAN: "PENDING_SCAN",
    ERROR_UPLOAD: 'ERROR_UPLOAD',
    ERROR_SCAN: 'ERROR_SCAN',
    OK: "OK",
}

export const INVOICES_RECONCILED_STATUS = {
    RECONCILED: "RECONCILED",
    NOT_RECONCILED: "NOT_RECONCILED",
    NOT_RECONCILED_LATE: "NOT_RECONCILED_LATE",
}

export const INVOICE_VALID_FORMATS = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/tiff',
]

export const PLANS = {
    FREELANCE_1: "freelance_1",
    FREELANCE_2: "freelance_2",
    BUSINESS_1: "business_1",
    BUSINESS_2: "business_2",
    BUSINESS_3: "business_3",
    PERSONAL: "personal",
    CUSTOM: "custom",
}

export const FREELANCE_SUBSCRIPTIONS = [PLANS.FREELANCE_1, PLANS.FREELANCE_2];
export const BUSINESS_SUBSCRIPTIONS = [PLANS.FREELANCE_1, PLANS.FREELANCE_2, PLANS.BUSINESS_1, PLANS.BUSINESS_2, PLANS.BUSINESS_3];
export const BUSINESS_PLUS_SUBSCRIPTIONS = [PLANS.BUSINESS_3, PLANS.CUSTOM];
export const STANDARD_SUBSCRIPTIONS = [PLANS.PERSONAL];

export const PROVIDER_CONNECTION_STATUSES = {
    CREATING_PROCESSING: 'CREATING_PROCESSING',
    CREATING_ERROR: 'CREATING_ERROR',
    CREATING_ERROR_FINAL: 'CREATING_ERROR_FINAL',

    RECONNECTING_PROCESSING: 'RECONNECTING_PROCESSING',
    RECONNECTING_ERROR: 'RECONNECTING_ERROR',
    RECONNECTING_SUCCESS: 'RECONNECTING_SUCCESS',

    SYNCHRONIZING: 'SYNCHRONIZING',
    SYNCHRONIZING_ERROR: 'SYNCHRONIZING_ERROR',
    SYNCHRONIZING_ERROR_OTP: 'SYNCHRONIZING_ERROR_OTP',
    SYNCHRONIZING_ERROR_EXPIRED: 'SYNCHRONIZING_ERROR_EXPIRED',

    OK: 'OK',
    EXPIRED: 'EXPIRED'
}

export const COOKIES = {
    FIRST_CONTACT: "bt-fc",
    LOCALE: "bt-locale",
    UTM_PARAMS: "bt-up",
    IDENTIFIED: 'bt-identified',
    LAST_APP_VERSION_RELOADED: 'bt-last-app-version-reloaded',
    AFFILIATE_CODE: 'bt-affiliate-code',
}

export const CONNECTOR_SERVICES = {
    wealth_reader: {
        name: "Wealth Reader",
        url: "https://www.wealthreader.com/",
    },
    arcopay: {
        name: "Arcopay",
        url: "https://www.arcopay.io/",
    },
    afterbanks_v3: {
        name: "Arcopay",
        url: "https://www.arcopay.io/",
    },
    afterbanks_psd2: {
        name: "Arcopay",
        url: "https://www.arcopay.io/",
    },
    nordigen: {
        name: "GoCardLess",
        url: "https://gocardless.com/",
    },
    indexa_capital: {
        name: "Indexa Capital",
        url: "https://indexacapital.com/",
    },
}

export const MODULE_CATEGORIES = {
    LISTS: "lists",
    TABLES: "tables",
    CHARTS: "charts",
    TEXT: "text"
}

export const CATEGORY_COLORS = [
    "#E04443", "#17B686", "#FFCF33", "#4E74EF", "#DE4AB3", "#0FB7A5", "#844BD6", "#FF7F5B", "#76AE31",
    "#21BCD0", "#C26630", "#929292", "#292BB2", "#8A398F", "#46789B", "#AB2020", "#5A5498", "#6F2D21"
]

export const MANUAL_PROVIDER_CONNECTOR_ID = "00YPPccYC3Tw6";

export const REPLAY_BLOCK_CLASS = "replay-block amp-block";

export const EMPTY_CONTACT_ID = "_empty";

export const RESULTS_PER_PAGE = 20;
