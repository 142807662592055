import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import useSynchronizeProviderConnection from "Hooks/useSynchronizeProviderConnection";
import syncHistoricalImage from "assets/sinc-historico.svg";
import Button from "Components/Button";
import { Trans, useTranslation } from "react-i18next";
import { es } from 'date-fns/locale';
import useLocale from "Hooks/useLocale";
import { formatDuration } from "date-fns";
import Select from 'Components/InputSelectSimple';

const getDuration = (days, { locale }) => {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const daysLeft = days - years * 365 - months * 30;

    return formatDuration({
        years,
        months,
        days: daysLeft,
    }, {
        format: ["years", "months", "days"],
        locale: locale === "es-ES" ? es : undefined,
    })
}

const ALL_PRODUCT_ID = "__all__";

export default function ProviderHistoricalSync(props) {
    const { providerConnection, onSync } = props;
    const { synchronize } = useSynchronizeProviderConnection();
    const [selectedProduct, setSelectedProduct] = useState(ALL_PRODUCT_ID);

    const { t } = useTranslation("providers");

    const productsOptions = useMemo(() => {
        const allProductOption = {
            id: ALL_PRODUCT_ID,
            label: t("all-products"),
        };

        const products = providerConnection?.products ?? [];
        const options = products.map(product => ({
            id: product.id,
            label: product.name,
        }));

        return [allProductOption, ...options];

    }, [providerConnection?.products, t]);

    const locale = useLocale();

    const handleSyncHistorical = () => {
        const product_id = selectedProduct === ALL_PRODUCT_ID ? null : selectedProduct;

        synchronize({ providerConnection, isCompleteSync: true, product_id });
        onSync();
    }

    const duration = getDuration(providerConnection?.connector?.update_delta_historical ?? 0, { locale });

    return (
        <div className="text-center">
            <div className="text-center mb-4">
                <img src={syncHistoricalImage} width={49} height={53} alt="" />
            </div>

            <h4 className="mb-3">
                {t("historical-sync")}
            </h4>

            <div className="mb-4 text-muted">
                <Trans t={t} i18nKey="historical-sync-description">
                    {{ days: duration }}
                </Trans>
            </div>

            <div className="mb-4 text-muted">
                {t("historical-sync-description-2")}
            </div>

            <div className="mb-4 text-start">
                <Select
                    options={productsOptions}
                    value={selectedProduct}
                    onChange={ev => setSelectedProduct(ev.target.value)}
                />
            </div>

            <div>
                <Button primary onClick={handleSyncHistorical}>
                    {t("sync")}
                </Button>
            </div>
        </div>
    )
}

ProviderHistoricalSync.propTypes = {
    onSync: PropTypes.func.isRequired,
    providerConnection: PropTypes.object.isRequired,
};
