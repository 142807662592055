import React from "react";
import PropTypes from "prop-types";
import styles from "./PriceTable.module.css";
import clsx from "clsx";
import Button from "Components/Button";
import { getSubcriptionLink } from "Services/user";
import { useQuery } from "@tanstack/react-query";
import { trackError } from "Utils/errorMonitoring";
import { PricePlanTableIcons } from "./PricePlanTableIcons";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "Utils/formatters";
import { motion, AnimatePresence } from "framer-motion";
import useLocale from "Hooks/useLocale";

const MIN_5 = 5 * 60 * 1000;

const getIconUrl = ({ iconName, disabled }) => {
    const icon = PricePlanTableIcons[iconName];

    if (!icon) {
        return "";
    }

    return disabled ? icon.disabled : icon.default;
}

export default function PricePlanTable(props) {
    const { title, description, classes, price, iconList, id, periodicity } = props;

    const { t } = useTranslation("pricing");

    const { data, isLoading } = useQuery({
        queryKey: ["subcription-link", id, periodicity],
        queryFn: () => getSubcriptionLink({ periodicity, plan: id }),
        enabled: !!id,
        staleTime: MIN_5,
        onError: error => trackError(error),
    });

    const locale = useLocale();
    const periodicityPrice = periodicity === 'yearly' ? price.yearly : price.monthly;
    const hasPrice = !!price;

    return (
        <div className={styles.planCard}>
            <div className={styles.planCardHeader}>
                <div className={styles.planCardContent}>
                    <AnimatePresence>
                        {periodicity === "yearly" && price.savings && (
                            <motion.div className={styles.save}
                                key="save"
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                            >
                                {t("save")}{" "}{formatCurrency(price.savings, { locale, maximumFractionDigits: 0 })}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <div className={clsx(styles.planCardTitle, classes?.title)}>
                        {title}
                    </div>
                    <div className={clsx(styles.priceSubtitle, "text-muted")}>{description}</div>
                    {/* {currentPlan && <div className={styles.current}>{t("annual-payment")}</div>} */}

                    {!hasPrice && <div className={`${styles.free} pt-4 text-muted`}>{t("free")}</div>}

                    {hasPrice && (
                        <div className="d-flex align-items-start gap-1 pt-4">
                            <strong>€</strong>
                            <strong className={styles.priceWhole}>{periodicityPrice}</strong>
                            <span>/{t("month")}</span>
                        </div>
                    )}

                    <AnimatePresence>
                        {periodicity === "yearly" && price.yearlyFullPrice && (
                            <motion.div
                                key="yearly-price"
                                initial={{ y: -10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: 10, opacity: 0 }}
                                className={styles.priceDescription}
                            >
                                {t("annual-payment-of")}{" "}{formatCurrency(price.yearlyFullPrice, { locale, maximumFractionDigits: 0 })}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
            <div className={styles.line} />

            <div className={styles.planCardContent}>
                <ul className={styles.list}>
                    {iconList.map((item, index) => (
                        <li className="d-flex align-items-center gap-2" key={`icon-list-${index}`}>
                            <div className="flex-shrink-1">
                                <img src={getIconUrl(item)} alt="" className={styles.featureIcon} />
                            </div>
                            <div style={{ opacity: item.disabled ? .5 : 1 }}>
                                <div className={styles.itemTitle}>{item.title}</div>
                                <div className={styles.itemDescription}>{item.description}</div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {id && (
                <div className={styles.planCardAction}>
                    <Button className="w-100" isBusy={isLoading} primary size="large" href={data?.link}>
                        {t("select")}
                    </Button>
                </div>
            )}
        </div>
    )
}

PricePlanTable.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    iconList: PropTypes.arrayOf(PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })).isRequired,
    periodicity: PropTypes.oneOf(["monthly", "yearly"]),
    currentPlan: PropTypes.bool,
    price: PropTypes.shape({
        monthly: PropTypes.number,
        yearly: PropTypes.number,
        yearlyFullPrice: PropTypes.number,
        savings: PropTypes.number,
    }),
    id: PropTypes.string,
    classes: PropTypes.shape({
        title: PropTypes.string,
    }),
}