import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getCategoriesSuggestions, applyCategorySuggestion } from "Services/transactions";
import { trackError } from "Utils/errorMonitoring";
import { useSearch } from "Components/SearchProvider";
import { RESULTS_PER_PAGE } from "Utils/constants";
import { useState } from "react";

export default function useCategoriesSuggestions() {
    const {
        data,
        isLoading,
        isFetching: isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch
    } = useInfiniteQuery({
        queryKey: ["categories-suggestions"],
        queryFn: async ({ pageParam }) => {
            const response = await getCategoriesSuggestions({
                page: pageParam
            });

            return response
        },
        initialPageParam: 1,
        refetchOnWindowFocus: false,
        onError: error => trackError(error),
        getNextPageParam: (lastPage, allPages) => {
            const pagesLoaded = allPages.length
            const totalPages = Math.ceil(lastPage.count / RESULTS_PER_PAGE)
            return pagesLoaded < totalPages ? pagesLoaded + 1 : false
        }
    })

    const categoriesSuggestions = data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.items]
    }, []) ?? [];

    const [index, setIndex] = useState(0);
    const [totalSuggestions, setTotalSuggestions] = useState(0);

    const initCategorization = () => {
        setIndex(0);

        setTotalSuggestions(data?.pages[0]?.count ?? 0);
    }

    const queryClient = useQueryClient();
    const { searchParams } = useSearch();

    const { mutate: applysuggestion } = useMutation({
        mutationFn: applyCategorySuggestion,
        onMutate: ({ id, action, categoryId, transactionId }) => {
            queryClient.setQueryData(["categories-suggestions"], (old) => {
                if (!old) return old;

                return {
                    ...old,
                    pages: old.pages.map(page => {
                        return {
                            ...page,
                            count: Math.max(1, page.count - 1),
                            items: page.items.map(suggestion => {
                                if (suggestion.id === id) {
                                    return {
                                        ...suggestion,
                                        response: action
                                    }
                                }
                                return suggestion
                            })
                        }
                    })
                }
            });

            if (action === "accept") {
                queryClient.setQueryData(["transactions", transactionId], old => {
                    if (!old) return old;

                    return {
                        ...old,
                        tags_ids: [...old.tags_ids, categoryId]
                    }
                });

                queryClient.setQueryData(["transactions", searchParams], old => {
                    if (!old) return old;

                    return {
                        ...old,
                        pages: old.pages.map(page => {
                            return {
                                ...page,
                                items: page.items?.map(transaction => {
                                    if (transaction.id === transactionId) {
                                        return {
                                            ...transaction,
                                            tags_ids: [...transaction.tags_ids, categoryId]
                                        }
                                    }
                                    return transaction
                                }) ?? []
                            }
                        })
                    }
                });
            }

            next();
        },
    })

    const count = Array.isArray(data?.pages) ? data.pages[0]?.count : 0;

    const categorieSuggestion = categoriesSuggestions[index];
    const nextCategorySuggestion = categoriesSuggestions[index + 1] ?? null;
    const hasFinished = totalSuggestions === index + 1;

    const next = () => {
        const diff = categoriesSuggestions.length - index;
        if (diff === 10 && hasNextPage) {
            fetchNextPage();
        }

        setIndex(index + 1);
    }
    const prev = () => {
        setIndex(index - 1);
    }

    const isFetching = !categorieSuggestion && isFetchingNextPage;

    const currentIndex = Math.min(index + 1, totalSuggestions);

    return {
        count,
        totalSuggestions,
        applysuggestion,
        isLoading,
        categorieSuggestion,
        nextCategorySuggestion,
        hasFinished,
        isFetching,
        currentIndex,
        next,
        prev,
        initCategorization,
        refetch
    }
}