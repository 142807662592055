import { useQuery } from "@tanstack/react-query";
import { getWorkspaceByProjectId } from "Services/user";
import { trackError } from "Utils/errorMonitoring";

export default function useProjectWorkspaces(projectId, { enabled = true } = {}) {
    const { data: workspaces = [], isLoading } = useQuery({
        queryKey: ["projects", projectId],
        queryFn: () => getWorkspaceByProjectId(projectId),
        enabled,
        onError: error => trackError(error),
    });

    return {
        workspaces,
        isLoading
    }
}
