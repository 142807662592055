import React from "react";
import classes from "./AppLayout.module.css";
import useProjectSelected from "Hooks/useProjectSelected";
import { getSubscriptionModifyLink } from "Services/user";
import { useErrors } from "Components/ErrorsProvider";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Alert from "Components/Alert";

export default function AppLayoutPaymentErrorCTA() {
    const { showUnknownError } = useErrors();

    const { project } = useProjectSelected();
    const { data, isLoading } = useQuery({
        queryKey: ["subcription-modify-link", project?.subscription?.plan],
        queryFn: getSubscriptionModifyLink,
        enabled: project?.subscription?.has_payment_error,
        onError: showUnknownError
    });

    const { t } = useTranslation("common");

    if (isLoading) {
        return null;
    }

    if (!project?.subscription?.has_payment_error) {
        return null;
    }

    return (
        <Alert
            className={classes.spaceBottom}
            variant="warning"
            title={t("payment-error")}
            description={t("payment-error-description")}
            cta={{
                label: t("payment-error-cta"),
                onClick: () => window.location.replace(data.link)
            }}
        />
    )
}
